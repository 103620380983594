import React, { useState } from 'react'

import {
  Dropdown as ReactstrapDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import './Dropdown.scss'

const Dropdown = ({ value, options, onChange, valueKey, labelKey }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOnChange = option => event => {
    event.preventDefault()
    onChange(option[valueKey])
    setIsOpen(false)
  }

  return (
    <ReactstrapDropdown isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle caret tag="a" className="editable">
        {value}
      </DropdownToggle>
      <DropdownMenu>
        {options.map(option => (
          <DropdownItem key={option[valueKey]} onClick={handleOnChange(option)}>
            {option[labelKey]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ReactstrapDropdown>
  )
}

export default Dropdown
