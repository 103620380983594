import gql from 'graphql-tag'

const AGGIORNA_CONFIGURAZIONE = gql`
  mutation aggiornaConfigurazione(
    $configurazione: AggiornaConfigurazioneInput!
  ) {
    aggiornaConfigurazione(configurazione: $configurazione)
  }
`

export default AGGIORNA_CONFIGURAZIONE
