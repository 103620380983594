import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Query, Mutation } from 'react-apollo'
import CONFIGURAZIONE_STANDARD from '../../../graphql/queries/configurazioneStandard'
import SALVA_CONFIGURAZIONE from '../../../graphql/mutations/salvaConfigurazione'

import { setConfigurazione, getConfigurazione } from '../../../redux/modules/configurazione'

import Layout from '../../shared/Layout/Layout'
import Configurazione from '../../shared/Configurazione/Configurazione'

const ConfigurazioneStandard = ({
  match,
  history,
  setConfigurazione,
  configurazione,
  thumbnailLampada,
}) => {
  const { tipoBaseId } = match.params

  const [backup, setBackup] = useState({})

  const handleCompletedConfigurazione = (data) => {
    const configurazione = {
      ...data.configurazioneStandard,
      isSalvata: false,
    }

    setConfigurazione(configurazione)
    setBackup(configurazione)
  }

  const handleReset = () => {
    setConfigurazione(backup)
  }

  const handleCompletedSave = (data) => {
    history.push(`/configurazioni/${data.salvaConfigurazione}`)
  }

  return (
    <Layout isLoggedIn>
      <div className="configurazione-standard">
        <Query
          query={CONFIGURAZIONE_STANDARD}
          variables={{ tipoBaseId }}
          onCompleted={handleCompletedConfigurazione}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <span>Loading....</span>
            }

            if (error) {
              return <span>Error</span>
            }
            return (
              <Mutation mutation={SALVA_CONFIGURAZIONE} onCompleted={handleCompletedSave}>
                {(salvaConfigurazione) => (
                  <Configurazione
                    backup={backup}
                    onSave={salvaConfigurazione}
                    onReset={handleReset}
                  />
                )}
              </Mutation>
            )
          }}
        </Query>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  configurazione: getConfigurazione(state),
})

export default connect(mapStateToProps, {
  setConfigurazione,
})(ConfigurazioneStandard)
