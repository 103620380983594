import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardImg, CardText, Row, Col } from 'reactstrap'

import { getTipiBaseByLampadaId, getLampadaByLampadaId } from '../../../redux/modules/lookups'

import Layout from '../../shared/Layout/Layout'

// Style
import './SceltaBase.scss'

const SceltaBase = ({ match, lampada, tipiBase }) => {
  const { tipoLampadaId } = match.params

  return (
    // title={lampada.nome} thumbnail={lampada.image}
    <Layout dark isLoggedIn>
      <div className="container">
        <div className="step2">
          <section className="heading dark">
            <div className="config-info">
              <h1 className="title">
                New configuration:{' '}
                <span className="subtitle">{`${lampada ? lampada.nome : null}`}</span>
              </h1>
            </div>
          </section>
          <h2 className="tip">Choose a standard Chandelier to start</h2>
          <Row>
            {tipiBase.map((base) => {
              return (
                <Col key={base.id} xl="2" lg="3" md="4" sm="6">
                  <div className="base">
                    <Link to={`/configurazioni/nuova/${tipoLampadaId}/${base.id}`}>
                      <Card>
                        <CardImg
                          tag="div"
                          style={{
                            backgroundImage: `url(/images/${base.id}.png)`,
                            backgroundSize: 'contain',
                            backgroundPosition: '50% 50%',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          {/* <Base
                            base={base}
                            coloreBase={coloreBase}
                            proiezione="z"
                          /> */}
                        </CardImg>
                        <CardBody>
                          <CardText tag="div">
                            <p className="forma">
                              Base{' '}
                              {base.forma === 'Round'
                                ? `\u2205 ${base.lunghezza}`
                                : `${base.lunghezza} x ${base.larghezza}`}{' '}
                              cm
                              <br />
                              {base.descrizioneThumbnail}
                            </p>
                            <p className="tipo-disposizione">{base.tipoDisposizione}</p>
                            <p className="codice">{base.id}</p>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Link>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state, ownProps) => ({
  tipiBase: getTipiBaseByLampadaId(state, ownProps.match.params.tipoLampadaId),
  lampada: getLampadaByLampadaId(state, ownProps.match.params.tipoLampadaId),
})

export default connect(mapStateToProps)(SceltaBase)
