import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Card } from 'reactstrap'
import { Query } from 'react-apollo'
import moment from 'moment'
import CONFIGURAZIONI_SALVATE from '../../../../graphql/queries/configurazioniSalvate'

// Style
import './ListaConfigurazioni.scss'

// Hook from useHooks.com
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

const ListaConfigurazioni = ({ history }) => {
  const [keywordsFilter, setKeywordsFilter] = useState('')

  const debouncedSearchTerm = useDebounce(keywordsFilter, 400)

  const handleChangeKeywordsFilter = event => {
    setKeywordsFilter(event.target.value)
  }

  const handleGoToDettaglioConfigurazione = id => event => {
    history.push(`/configurazioni/${id}`)
  }

  // const handleDuplicaConfigurazione = event => {
  //   event.stopPropagation()
  // }

  // const handleGeneraPdf = event => {
  //   event.stopPropagation()
  // }

  return (
    <div className="lista-configurazioni">
      <Row>
        <Col lg="6">
          <section className="heading dark">
            <div className="config-info">
              <h1 className="title">Configurations archive</h1>
            </div>
          </section>
        </Col>
        <Col lg="6">
          <div className="input-group input-group-lg mb-3 input-search">
            <input
              type="text"
              value={keywordsFilter}
              onChange={handleChangeKeywordsFilter}
              className="form-control dark-input"
              placeholder="Filter your configurations"
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon1">
                <svg viewBox="0 0 24 24">
                  <path d="M15.9,16.6c-1.7,1.5-3.9,2.4-6.4,2.4C4.3,19,0,14.7,0,9.5S4.3,0,9.5,0S19,4.3,19,9.5c0,2.4-0.9,4.7-2.4,6.4l7.4,7.4L23.3,24L15.9,16.6z M9.5,1C14.2,1,18,4.8,18,9.5S14.2,18,9.5,18S1,14.2,1,9.5S4.8,1,9.5,1z" />
                </svg>
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Query
          query={CONFIGURAZIONI_SALVATE}
          variables={{ keywords: debouncedSearchTerm }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            // if (loading) {
            //   return null
            // }

            if (error) {
              return <div>An error occurred while loading your configurations</div>
            }

            const configurazioni = data.configurazioni ? data.configurazioni.nodes : []

            if (configurazioni.length < 1) {
              return (
                <h3 className="text-center mx-auto no-found">There are no saved configurations</h3>
              )
            }

            return configurazioni.map(configurazione => {
              return (
                <Col className="column-card" sm="6" md="4" lg="3" key={configurazione.id}>
                  <Card
                    body
                    className="configurazione"
                    onClick={handleGoToDettaglioConfigurazione(configurazione.id)}
                  >
                    <div className="left">
                      <div className="data">
                        {moment(configurazione.data, 'YYYY-MM-DD HH:mm', true).format('DD/MM/YYYY')}
                      </div>
                      <div className="utente">
                        {configurazione.utente.nome}
                      </div>
                      <div className="nome">
                        <div className="codice">{configurazione.codice}</div>
                        <h2>{configurazione.nome}</h2>
                      </div>
                      <div className="lampada">
                        {configurazione.tipoBase.tipoLampada.nome.toUpperCase()}
                        <br />
                        {configurazione.numeroPendenti + ' PENDANTS '}
                        <br />
                        {configurazione.tipoBase.forma === 'Round'
                          ? `\u2205 ${configurazione.tipoBase.lunghezza}`
                          : `${configurazione.tipoBase.lunghezza} x ${configurazione.tipoBase.larghezza}`}{' '}
                        cm
                        {/* {configurazione.tipoBase.lunghezza} x {configurazione.tipoBase.larghezza} cm */}
                        <br />H MAX {configurazione.hMax} cm
                      </div>
                    </div>
                    <div className="right">
                      <svg viewBox="0 0 86 86">
                        <circle className="circle" cx="43.9" cy="43" r="40.5" />
                        <path
                          className="arrow"
                          d="M55.8,50.2L67.9,43l-12.1-7.2c0.8,2.4,2.1,4.5,3.8,6.3H19.9v1.7h39.6C57.9,45.6,56.6,47.8,55.8,50.2z"
                        />
                        <rect
                          className="arrow"
                          x="38.5"
                          y="23.2"
                          transform="matrix(-1.961526e-08 1 -1 -1.961526e-08 82.708 3.2678)"
                          width="2.5"
                          height="39.6"
                        />
                      </svg>
                    </div>
                  </Card>
                </Col>
              )
            })
          }}
        </Query>
      </Row>
    </div>
  )
}

export default withRouter(ListaConfigurazioni)
