import { distance2d } from './collisionDetection'

const DISTANZA_OMBRA = 20

const shadowDetection = (pendenti, pendenteMosso) => {
  if (pendenteMosso.tipoPendenteId !== 'SPL') {
    // No shadow problems
    return pendenti
  }

  // SPOTLIGHT
  const altriPendentiGMVicini = pendenti.filter((pendente) => {
    if (pendente.progressivo === pendenteMosso.progressivo || pendente.tipoPendenteId !== 'GM') {
      return false
    }

    // E' un GM => Vediamo se è lontano
    if (distance2d(pendente, pendenteMosso) > DISTANZA_OMBRA) {
      return false
    }

    // E' un GM vicino
    return true
  })

  const problemaOmbra = altriPendentiGMVicini.reduce((acc, pendenteGM) => {
    const distanzaZ = pendenteGM.z - pendenteMosso.z
    if (distanzaZ > 5) {
      return true
    }
    return acc
  }, false)

  return pendenti.map((pendente) => {
    if (pendente.progressivo !== pendenteMosso.progressivo) {
      return pendente
    }
    // E' quello mosso
    if (!problemaOmbra) {
      return pendente
    }
    // E' lo spotlight mosso e ha problema ombra
    return {
      ...pendente,
      shadow: true,
    }
  })
}

export default shadowDetection
