import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import ME from '../../../graphql/queries/me'
import logo from '../../../images/catellani_smith.svg'

import './Header.scss'

const Header = ({ isLoggedIn, history }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const logout = apolloClient => event => {
    event.preventDefault()
    apolloClient.resetStore()
    localStorage.removeItem('catellani_token')
    history.push('/login')
  }

  return (
    <header>
      <Navbar dark expand="md">
        <div className="container">
          <NavbarBrand tag={Link} to="/">
            <img src={logo} alt="Catellani & Smith" id="catellani_smith_logo" />
          </NavbarBrand>

          {isLoggedIn && (
            <React.Fragment>
              <NavbarToggler onClick={handleToggleMenu} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                  <div className="menu-center-group">
                    <NavItem>
                      <NavLink tag={Link} to="/configurazioni">
                        Configurations Archive
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        New Configuration
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <NavLink tag={Link} to="/configurazioni/nuova/PK">
                            Postkrisi
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink tag={Link} to="/configurazioni/nuova/SL">
                            Sweet Light
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink tag={Link} to="/configurazioni/nuova/JO">
                            Jackie O
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink tag={Link} to="/configurazioni/nuova/GM">
                            Gold Moon
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Query query={ME}>
                    {({ client, data, loading, error }) => {
                      if (loading || error) {
                        return null
                      }

                      return (
                        <UncontrolledDropdown className="dropdown-logout" nav inNavbar>
                          <div className="logout">
                            <DropdownToggle nav caret>
                              <svg viewBox="0 0 24 24">
                                <path
                                  d="M12,0c6.6,0,12,5.4,12,12s-5.4,12-12,12S0,18.6,0,12S5.4,0,12,0z M20.1,19.4c-0.3-0.4-0.8-0.7-1.6-0.9c-3.8-0.9-4.1-1.5-4.4-2.1c-0.3-0.6-0.2-1.2,0.2-2c1.7-3.3,2.1-6,1-7.8C14.7,5.6,13.5,5,12,5c-1.5,0-2.7,0.6-3.4,1.8c-1.1,1.8-0.7,4.5,1,7.8c0.4,0.7,0.5,1.4,0.2,2c-0.3,0.6-0.6,1.2-4.4,2.1c-0.9,0.2-1.3,0.4-1.6,0.8c2,2.2,4.9,3.6,8.1,3.6S18.1,21.6,20.1,19.4z M20.8,18.6c1.4-1.8,2.2-4.1,2.2-6.6c0-6.1-4.9-11-11-11S1,5.9,1,12c0,2.5,0.8,4.8,2.2,6.6c0.4-0.5,1-0.8,2-1c2-0.5,3.4-0.8,3.7-1.5C9.1,15.8,9,15.5,8.8,15c-1.9-3.5-2.3-6.6-1-8.7C8.6,4.8,10.1,4,12,4c1.8,0,3.4,0.8,4.2,2.2c1.3,2.1,0.9,5.2-1,8.8c-0.2,0.5-0.3,0.8-0.2,1.1c0.3,0.7,1.6,1,3.7,1.5C19.7,17.8,20.4,18.2,20.8,18.6z"
                                />
                              </svg>
                              
                              {data.me.nome}
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem onClick={logout(client)}>Log out</DropdownItem>
                            </DropdownMenu>
                          </div>
                        </UncontrolledDropdown>
                      )
                    }}
                  </Query>
                </Nav>
              </Collapse>
            </React.Fragment>
          )}
        </div>
      </Navbar>
    </header>
  )
}

export default withRouter(Header)
