import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import {
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap'

import { RIENumber } from 'riek'
// import Tooltip from 'rc-tooltip'
// import Slider from 'rc-slider'

import creaPdf from '../../../lib/creaPdf'
import Dropdown from '../../shared/Dropdown/Dropdown'
import Accordion from '../../shared/Accordion/Accordion'

import {
  //setConfigurazione,
  updateBase,
  updatePendente,
  updateAltezzaPendente,
  updateAltezzaMax,
  calibraConfigurazione,
  asyncRoundAltezzaPendente,
  updateNomeConfigurazione,
  getConfigurazione,
  getConfigurazioneDaSalvare,
  updateHMinReference,
  updateHMaxReference,
} from '../../../redux/modules/configurazione'
import {
  getConnessioniPendentiByTipoLampadaId,
  getConnessionePendentiNomeById,
} from '../../../redux/modules/lookups'

import Base from '../../shared/Base/Base'

import INVIA_EMAIL from '../../../graphql/mutations/inviaEmail'
import DUPLICA_CONFIGURAZIONE from '../../../graphql/mutations/duplicaConfigurazione'
import ELIMINA_CONFIGURAZIONE from '../../../graphql/mutations/eliminaConfigurazione'

import 'react-toastify/dist/ReactToastify.css'
import 'rc-slider/assets/index.css'
import './Configurazione.scss'

// const createSliderWithTooltip = Slider.createSliderWithTooltip
// // const Range = createSliderWithTooltip(Slider.Range);
// const Handle = Slider.Handle

// const handle = props => {
//   const { value, dragging, index, ...restProps } = props
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={value}
//       visible={dragging}
//       placement="top"
//       key={index}
//     >
//       <Handle value={value} {...restProps} />
//     </Tooltip>
//   )
// }

const notifyAltezzeReferenceSbagliate = (hMinRef, hMaxRef) => {
  if (hMinRef > 0 && hMaxRef > 0 && hMinRef > hMaxRef) {
    return toast.error('You cannot set " REF MIN H " greater than the MAX')
  }
  if (hMinRef < 35 || hMaxRef < 35) {
    return toast.error('Values less than minimum')
  }
}

const notifyHMaxOutOfRange = (hMax) =>
  toast.error(
    hMax < 35
      ? 'The selected height for the pendant is under the minimum allowed'
      : 'The selected height for the pendant is above the maximum allowed'
  )

const notifySavedConfigSuccess = () => toast.success('Configuration saved')
const notifySavedConfigFailed = () =>
  toast.error("Error. The configuration hasn't been saved")

const Configurazione = ({
  backup,
  configurazione,
  configurazioneDaSalvare,
  lookups,
  connessioniPendenti,
  connessionePendentiNome,
  history,
  updateBase,
  updatePendente,
  updateAltezzaPendente,
  updateAltezzaMax,
  updateHMinReference,
  updateHMaxReference,
  calibraConfigurazione,
  asyncRoundAltezzaPendente,
  updateNomeConfigurazione,
  onSave,
  onReset,
}) => {
  const { base, pendenti } = configurazione
  ////////////////////////////////////////////////////////////////////
  // HOOKS

  // Button Proiezioni
  const [proiezione, setProiezione] = useState('y')

  // Messaggio email
  const [inviaMail, setInviaMail] = useState({
    messaggio: '',
    inviaUtenteFlag: false,
  })

  const [proiezioneZPdfOnly, setProiezioneZPdfOnly] = useState(false)
  // const [zPendenteValid, setZPendenteValid] = useState(true)

  ////////////////////////////////////////////////////////////////////
  // EVENT HANDLERS

  const handleChangeNomeConfigurazione = (event) => {
    updateNomeConfigurazione(event.target.value.toUpperCase())
  }

  const handleUpdateDettagliBase = (field) => (value) => {
    updateBase({ [field]: value })
  }

  const handleUpdateTipoPendente = (progressivo) => (tipoPendenteId) => {
    const coloreDefault = lookups.tipiColorePendente.find(
      (t) => t.tipoPendenteId === tipoPendenteId && t.isDefault
    ).id
    updatePendente({
      progressivo,
      tipoPendenteId,
      tipoColorePendenteId: coloreDefault,
    })
  }

  const handleUpdateColorePendente =
    (progressivo) => (tipoColorePendenteId) => {
      updatePendente({ progressivo, tipoColorePendenteId })
    }

  const handleChangeProiezione = (proiezione) => {
    setProiezione(proiezione)
  }

  // SALVA CONFIGURAZIONE
  const handleSaveConfigurazione = (event) => {
    event.preventDefault()
    onSave({
      variables: {
        configurazione: configurazioneDaSalvare,
      },
    }).then(
      (data) => notifySavedConfigSuccess(),
      (error) => notifySavedConfigFailed()
    )
  }

  // CREA PDF
  const handleCreaPdf = async () => {
    setProiezioneZPdfOnly(true)
    await creaPdf(configurazione, dimensioni, connessionePendentiNome)
    setProiezioneZPdfOnly(false)
  }

  const handleChangeMessaggio = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setInviaMail({
      ...inviaMail,
      [name]: value,
    })
  }

  // INVIA EMAIL A CATELLANI
  const handleInviaEmail = (inviaEmail) => (event) => {
    event.preventDefault()
    inviaEmail()
  }

  const handleCompletedInviaEmail = (data) => {
    toast.success('Email inviata')
  }

  const handleErrorInviaEmail = (data) => {
    toast.error(`E' avvenuto un errore durante l'invio della mail`)
  }

  // DUPLICA CONFIGURAZIONE
  const handleDuplicaConfigurazione = (duplicaConfigurazione) => (event) => {
    event.preventDefault()
    duplicaConfigurazione()
  }

  const handleCompletedDuplica = (data) => {
    history.push(`/configurazioni/${data.duplica}`)
  }

  // ELIMINA CONFIGURAZIONE
  const handleCompletedElimina = (data) => {
    history.push(`/configurazioni/`)
  }

  const handleChangeHMax = ({ hMax }) => {
    const normalizedHMax = parseInt(hMax)
    const value =
      normalizedHMax < 35 ? 35 : normalizedHMax > 600 ? 600 : normalizedHMax
    updateAltezzaMax(value)
  }

  const handleChangeZ =
    (pendente) =>
    ({ z }) => {
      const currentValue = pendente.z
      const newValue = parseInt(z)
      if (newValue < 35 || newValue > configurazione.hMax) {
        // OPEN TOASTER
        notifyHMaxOutOfRange(z)
        // RESET VALUE
        updateAltezzaPendente({
          progressivo: pendente.progressivo,
          z: currentValue,
        })
        asyncRoundAltezzaPendente(pendente.progressivo)
      } else {
        // IN RANGE
        updateAltezzaPendente({
          progressivo: pendente.progressivo,
          z: newValue,
        })
        asyncRoundAltezzaPendente(pendente.progressivo)
      }
    }

  // CALIBRA CONFIGURAZIONE
  const handleCalibraConfigurazione = () => {
    const { pendenti: pendentiStandartCatellani } = backup

    const altezzaNuovaConfigurazione =
      configurazione.hMaxReference - configurazione.hMinReference

    if (altezzaNuovaConfigurazione > 0) {
      calibraConfigurazione(
        altezzaNuovaConfigurazione,
        pendentiStandartCatellani,
        configurazione
      )
    }
    if (altezzaNuovaConfigurazione < 0) {
      notifyAltezzeReferenceSbagliate(
        configurazione.hMinReference,
        configurazione.hMaxReference
      )
    }
  }

  // CAMBIA MIN H PER RICALIBRARE
  const handleChangehMinReference = ({ hMinReference }) => {
    if (+hMinReference >= 35) {
      updateHMinReference(hMinReference)
    } else {
      notifyHMaxOutOfRange(hMinReference)
      updateHMinReference(configurazione.hMinReference)
    }
  }

  // CAMBIA MAX H PER RICALIBRARE

  const handleChangehMaxReference = ({ hMaxReference }) => {
    if (+hMaxReference <= configurazione.hMax) {
      updateHMaxReference(hMaxReference)
    } else {
      notifyHMaxOutOfRange(hMaxReference)
      updateHMaxReference(configurazione.hMaxReference)
    }
  }

  // SLIDER
  // const handleAltezzaMax = value => {
  //   updateAltezzaMax(value)
  // }

  //////////////////////////////////////////
  // RENDER TODO > SPOSTARE IN LIB
  const dimensioni =
    configurazione.forma === 'Round'
      ? `ø ${base.lunghezza}`
      : `${base.lunghezza} x ${base.larghezza}`

  if (!pendenti) {
    return null
  }

  const altezzaMaxMinima = configurazione.hMaxIniziale
  const altezzaMaxMassima = 600

  const sliderMarks = {}

  sliderMarks[altezzaMaxMinima] = ''

  let hCorrente = 0

  for (let i = altezzaMaxMinima; i < altezzaMaxMassima; i++) {
    hCorrente = Math.floor(i / 50) * 50
    sliderMarks[hCorrente + 50] = hCorrente + 50
  }
  // Altezza css in px del container pendenti -> 700 : hMaxIniziale = x : hMax
  const HCSS0 = 700
  const hCssRicalcolata =
    (HCSS0 * configurazione.hMax) / configurazione.hMaxIniziale

  const scale = (665 / configurazione.hMax) * (hCssRicalcolata / HCSS0)

  const pendentiEccedentiHmax = pendenti.filter(
    (pendente) => pendente.z > configurazione.hMax
  )

  const resetAlertOptions = {
    customUI: ({ onClose }) => {
      return (
        <div className="reset-alert">
          <div className="title">
            Are you sure you want to reset to the standard configuration?
          </div>
          <Button
            color="white"
            onClick={() => {
              onReset()
              onClose()
            }}
          >
            Yes, reset
          </Button>
          <Button color="grey" onClick={onClose}>
            No, continue configuration
          </Button>
        </div>
      )
    },
  }

  const deleteAlertOptions = (eliminaConfigurazione) => {
    return {
      customUI: ({ onClose }) => {
        return (
          <div className="delete-alert">
            <div className="title">
              Are you sure you want to delete your configuration?
            </div>
            <Button
              color="white"
              onClick={() => {
                eliminaConfigurazione()
                onClose()
              }}
            >
              Yes, delete
            </Button>
            <Button color="grey" onClick={onClose}>
              No
            </Button>
          </div>
        )
      },
    }
  }

  const getPendentiOptions = (tipiPendenti, currentPendenteId) => {
    // cannot change a spotlight to gold moon and vice versa
    // (spotlight positions on the base are fixed)
    // The same for the Par18
    return currentPendenteId === 'GM' ||
      currentPendenteId === 'P188' ||
      currentPendenteId === 'P1816'
      ? tipiPendenti.filter((p) => p.id !== 'SPL')
      : currentPendenteId === 'SPL'
      ? tipiPendenti.filter(
          (p) => p.id !== 'GM' && p.id !== 'P188' && p.id !== 'P1816'
        )
      : tipiPendenti
  }

  const wattWithDot = base.lampada.lampadineWatt.toString()
  //const lampadineWatt = wattWithDot.replace('.', ',')

  return (
    <div className="configurazione">
      <ToastContainer />
      <section className="heading">
        <div className="container">
          <div className="config-info">
            <h1 className="title">
              {configurazione.base.lampada.nome} - code {base.id} -{' '}
              {pendenti.length} pendants
            </h1>
            <h2>{configurazione.nome || 'Untitled Configuration'}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <Card>
            <Row>
              <Col lg="5">
                <CardImg tag="div" className="proiezioneZ">
                  {proiezioneZPdfOnly ? (
                    <Base
                      configurazione={configurazione}
                      proiezione="z"
                      pdf={true}
                    />
                  ) : (
                    <Base configurazione={configurazione} proiezione="z" />
                  )}
                  {/* <Base configurazione={configurazione} proiezione="z" /> */}
                </CardImg>
                <div className="btns-proiezione">
                  <Button
                    color={proiezione === 'y' ? 'grey' : 'white'}
                    onClick={() => handleChangeProiezione('y')}
                  >
                    Front view
                  </Button>
                  <Button
                    color={proiezione === 'x' ? 'grey' : 'white'}
                    onClick={() => handleChangeProiezione('x')}
                  >
                    Side view
                  </Button>
                </div>
                <CardImg tag="div" className="svg-base-model proiezioneXY">
                  <div className="disegno">
                    <Base
                      configurazione={configurazione}
                      proiezione={proiezione}
                      scale={scale}
                      style={{ height: hCssRicalcolata, width: '100%' }}
                    />
                    <div style={{ display: 'none' }}>
                      <Base
                        configurazione={configurazione}
                        proiezione={proiezione === 'y' ? 'x' : 'y'}
                        scale={scale}
                        style={{ height: hCssRicalcolata, width: '100%' }}
                      />
                    </div>
                  </div>
                </CardImg>
              </Col>
              <Col lg="7">
                <div className="parametri">
                  <CardBody>
                    <Accordion heading="Chandelier configuration">
                      <div className="table-responsive parametri-base">
                        <CardText tag="table" className="table">
                          <tbody>
                            <tr>
                              <td className="configurazione-label">Base</td>
                              <td className="configurazione-valori">
                                <p>
                                  {base.id}{' '}
                                  {configurazione.isModificatoDefault && (
                                    <span>(edited)</span>
                                  )}
                                </p>
                                <p>{base.tipoDisposizione}</p>
                                <p>
                                  {base.forma}{' '}
                                  {base.forma === 'Round'
                                    ? `\u2205 ${base.lunghezza}`
                                    : `${base.lunghezza} x ${base.larghezza}`}{' '}
                                  cm
                                </p>
                                <div className="colore-base">
                                  <span>Colour:</span>
                                  <div
                                    className="indicatore-colore"
                                    style={{
                                      backgroundColor: base.tipoColoreBase.hsl,
                                    }}
                                  />
                                  <Dropdown
                                    value={
                                      configurazione.base.tipoColoreBase.nome
                                    }
                                    valueKey="id"
                                    labelKey="nome"
                                    options={lookups.tipiColoreBase}
                                    onChange={handleUpdateDettagliBase(
                                      'tipoColoreBaseId'
                                    )}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="configurazione-label">Pendants</td>
                              <td className="configurazione-valori">
                                {base.numeroPendentiPerTipologia.map(
                                  (tipologiaPendente) => (
                                    <p
                                      key={`${tipologiaPendente.tipoPendenteId}_${tipologiaPendente.nomeColore}`}
                                      style={{ marginBottom: 0 }}
                                    >
                                      {tipologiaPendente.numero} x{' '}
                                      {tipologiaPendente.tipoPendenteId}{' '}
                                      {tipologiaPendente.nomePendente}{' '}
                                      {tipologiaPendente.tipoPendenteId !==
                                        'JO' &&
                                        tipologiaPendente.tipoPendenteId !==
                                          'SPL' &&
                                        `\u2205 ${tipologiaPendente.diametro} cm `}
                                      (
                                      {tipologiaPendente.nomeColore.toLowerCase()}
                                      )
                                    </p>
                                  )
                                )}
                              </td>
                            </tr>
                            {/* <tr>
                              <td className="configurazione-label">Pendants connection</td>
                              <td className="configurazione-valori">
                                <Dropdown
                                  value={connessionePendentiNome}
                                  valueKey="id"
                                  labelKey="nome"
                                  options={connessioniPendenti}
                                  onChange={handleUpdateDettagliBase('connessionePendentiId')}
                                />
                              </td>
                            </tr> */}
                            <tr>
                              <td className="configurazione-label">
                                Light source
                              </td>
                              <td className="configurazione-valori">
                                {base.wattaggio}
                                {/* {`${pendenti.length} x ${lampadineWatt} W ${base.lampada.lampadineTipo}`} */}
                              </td>
                            </tr>
                            <tr>
                              <td className="configurazione-label">
                                Compatible dimming
                              </td>
                              <td className="configurazione-valori">
                                <Dropdown
                                  value={
                                    configurazione.base.tipoDimmerazione.nome
                                  }
                                  valueKey="id"
                                  labelKey="nome"
                                  options={lookups.tipiDimmerazione}
                                  onChange={handleUpdateDettagliBase(
                                    'tipoDimmerazioneId'
                                  )}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="configurazione-label">
                                Cable colour
                              </td>
                              <td className="configurazione-valori">
                                {base.lampada.coloreCavo}
                              </td>
                            </tr>
                          </tbody>
                        </CardText>
                      </div>
                    </Accordion>
                    <Accordion heading="Pendants configuration">
                      {/* <div className="slider-altezza">
                        <h6 className="text-uppercase">
                          Modifica altezza massima
                        </h6>
                        <div className="wrapper-slider">
                          <Slider
                            min={configurazione.hMaxIniziale}
                            max={900}
                            value={configurazione.hMax}
                            handle={handle}
                            onChange={handleAltezzaMax}
                            marks={sliderMarks}
                            railStyle={{ backgroundColor: '#e0e7eb' }}
                            trackStyle={{ backgroundColor: '#6c757d' }}
                            handleStyle={{
                              borderColor: '#6c757d',
                            }}
                            dotStyle={{
                              backgroundColor: '#e0e7eb',
                              borderColor: '#e0e7eb',
                            }}
                            step={null}
                          />
                        </div>
                      </div> */}
                      <div className="altezze-min-max mt-4">
                        <p>
                          {`STANDARD CONFIGURATION: MIN H
                          ${configurazione.hMinStandard} cm / MAX H
                          ${configurazione.hMaxStandard} cm`}
                        </p>
                      </div>
                      <div className="altezze-min-max">
                        YOU CAN CONFIGURATE YOUR CHANDELIER BETWEEN THESE
                        DIMENSIONS: <span className="limite">MIN H 35 cm</span>{' '}
                        / MAX H 600 cm
                      </div>

                      <div className="altezze-min-max">
                        CHOOSE YOUR <span className="limite">MAX HEIGHT</span>
                        <RIENumber
                          editProps={{
                            step: 5,
                            min: 0,
                            max: 600,
                          }}
                          className="editable h-soffitto limite"
                          classEditing="form-control"
                          value={configurazione.hMax}
                          change={handleChangeHMax}
                          propName="hMax"
                        />
                        <span className="limite"> cm</span>
                      </div>
                      <div className="wrapper-min-max-h">
                        <div className="left-side-wrapper">
                          <div className="first-child">
                            {/* TODO
                            SE SI CAMBIA MIN H DA QUA CAMBIARE MIN H PENDENTE */}
                            <label className="label">Ref Min H</label>
                            <RIENumber
                              propName="hMinReference"
                              value={
                                configurazione.hMinReference ||
                                configurazione.altezzeMinMax.min
                              }
                              change={handleChangehMinReference}
                              editProps={{
                                step: 5,
                                min: 0,
                                max: 600,
                              }}
                              className="editable h-soffitto limite"
                              classEditing="form-control"
                            />
                          </div>
                          <div>
                            <label className="label">Ref Max H</label>
                            <RIENumber
                              propName="hMaxReference"
                              value={
                                configurazione.hMaxReference ||
                                configurazione.altezzeMinMax.max
                              }
                              change={handleChangehMaxReference}
                              editProps={{
                                step: 5,
                                min: 0,
                                max: 600,
                              }}
                              className="editable h-soffitto limite"
                              classEditing="form-control"
                            />
                          </div>
                        </div>
                        <Button
                          color="white"
                          // onClick={handleResetConfigurazione}
                          onClick={handleCalibraConfigurazione}
                        >
                          Distribute
                        </Button>
                      </div>

                      {configurazione.atLeastOneCollision && (
                        <div className="alert alert-danger">
                          Warning: some pendants are too close to each other.
                          <br />
                          This configuration cannot be saved.
                        </div>
                      )}

                      {configurazione.atLeastOneShadow && (
                        <div className="alert alert-warning">
                          Warning: some Gold Moon leaves are shadowing at least
                          one SpotLight nearby.
                          <br />
                          This configuration cannot be saved.
                        </div>
                      )}

                      {configurazione.mixedGmColors && (
                        <div className="alert alert-danger">
                          Warning: you can't mix gold and silver coloured
                          pendants.
                          <br />
                          This configuration cannot be saved.
                        </div>
                      )}

                      {pendentiEccedentiHmax.length > 0 && (
                        <div className="alert alert-danger">
                          Warning: at least one pendant exceeds the max height
                          selected.
                        </div>
                      )}

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Pendant ref.</th>
                              <th>H from ceiling</th>
                              {/* <th>H from base</th> */}
                              <th>Type</th>
                              <th>Colour</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pendenti.map((pendente) => {
                              return (
                                <tr
                                  key={pendente.progressivo}
                                  className={
                                    pendente.progressivo === base.activePendente
                                      ? 'activePendente'
                                      : null
                                  }
                                >
                                  <td>{pendente.progressivo}</td>
                                  <td>
                                    <RIENumber
                                      className="editable h-soffitto"
                                      classEditing="form-control"
                                      value={pendente.z}
                                      change={handleChangeZ(pendente)}
                                      propName="z"
                                      editProps={{
                                        step: 5,
                                        min: 0,
                                        max: 600,
                                      }}
                                    />
                                    cm
                                  </td>
                                  {/* <td>{pendente.z - 5}</td> */}
                                  {getPendentiOptions(
                                    base.tipiPendenti,
                                    pendente.tipoPendenteId
                                  ).length === 1 ? (
                                    <td>{pendente.tipoPendenteId}</td>
                                  ) : (
                                    <td>
                                      <Dropdown
                                        value={pendente.tipoPendenteId}
                                        valueKey="id"
                                        labelKey="nome"
                                        options={getPendentiOptions(
                                          base.tipiPendenti,
                                          pendente.tipoPendenteId
                                        )}
                                        onChange={handleUpdateTipoPendente(
                                          pendente.progressivo
                                        )}
                                      />
                                    </td>
                                  )}
                                  {pendente.tipiColoriPendenti.length === 1 ? (
                                    <td>{pendente.tipoColorePendente.nome}</td>
                                  ) : (
                                    <td>
                                      <Dropdown
                                        value={pendente.tipoColorePendente.nome}
                                        valueKey="id"
                                        labelKey="nome"
                                        options={pendente.tipiColoriPendenti}
                                        onChange={handleUpdateColorePendente(
                                          pendente.progressivo
                                        )}
                                      />
                                    </td>
                                  )}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="btns-reset-configurazione">
                        <Button
                          color="grey"
                          // onClick={handleResetConfigurazione}
                          onClick={() => confirmAlert(resetAlertOptions)}
                        >
                          {/* TODO
                          RESETTARE ANCHE REF MIN H */}
                          Reset configuration
                        </Button>
                        <Button
                          color="white"
                          // onClick={handleResetConfigurazione}
                          onClick={handleCalibraConfigurazione}
                        >
                          Distribute
                        </Button>
                      </div>
                    </Accordion>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
        <div className="card-actions">
          <div className="container">
            <Card>
              <CardBody>
                <CardText tag="div">
                  <h2 className="salva-configurazione-title">
                    {configurazione.isSalvata ? 'Manage' : 'Save'} your
                    configuration
                  </h2>
                  <div className="wrapper-actions" style={{ height: '100%' }}>
                    <form onSubmit={handleSaveConfigurazione}>
                      <div className="input-group input-group-lg">
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="progressivo"
                          >
                            <InputGroupText>
                              {configurazione.isSalvata
                                ? `${configurazione.codice}-`
                                : ''}
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            type="text"
                            id="nome-configurazione"
                            className="form-control dark-input"
                            placeholder="Insert name"
                            value={configurazione.nome}
                            onChange={handleChangeNomeConfigurazione}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-dark-grey salva-configurazione"
                              type="submit"
                              id="salva-configurazione"
                              {...(configurazione.atLeastOneCollision ||
                              configurazione.atLeastOneShadow ||
                              configurazione.mixedGmColors ||
                              !configurazione.nome
                                ? { disabled: true }
                                : {})}
                            >
                              Save
                            </button>
                          </div>
                        </InputGroup>
                      </div>
                    </form>

                    {configurazione.isSalvata && (
                      <Button color="white" onClick={handleCreaPdf}>
                        Save as pdf
                      </Button>
                    )}

                    {configurazione.isSalvata && (
                      <Mutation
                        mutation={DUPLICA_CONFIGURAZIONE}
                        onCompleted={handleCompletedDuplica}
                        variables={{
                          configurazioneId: parseInt(configurazione.id, 10),
                        }}
                      >
                        {(duplicaConfigurazione) => (
                          <Button
                            color="white"
                            onClick={handleDuplicaConfigurazione(
                              duplicaConfigurazione
                            )}
                          >
                            Save configuration copy
                          </Button>
                        )}
                      </Mutation>
                    )}
                    {configurazione.isSalvata && (
                      <Mutation
                        mutation={ELIMINA_CONFIGURAZIONE}
                        onCompleted={handleCompletedElimina}
                        variables={{
                          id: parseInt(configurazione.id, 10),
                        }}
                      >
                        {(eliminaConfigurazione) => (
                          <Button
                            color="red"
                            onClick={() =>
                              confirmAlert(
                                deleteAlertOptions(eliminaConfigurazione)
                              )
                            }
                          >
                            Delete
                          </Button>
                        )}
                      </Mutation>
                    )}
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
        {configurazione.isSalvata && (
          <div className="invia-richiesta">
            <div className="container">
              <Card>
                <CardBody>
                  <CardText tag="div">
                    <h2 className="text-center mb-4">
                      Send your configuration to Catellani & Smith to receive a
                      quotation
                    </h2>
                    <Row>
                      <Col lg={{ size: 8, offset: 2 }}>
                        <Mutation
                          mutation={INVIA_EMAIL}
                          variables={{
                            configurazioneId: parseInt(configurazione.id, 10),
                            messaggio: inviaMail.messaggio,
                            inviaUtenteFlag: inviaMail.inviaUtenteFlag,
                          }}
                          onCompleted={handleCompletedInviaEmail}
                          onError={handleErrorInviaEmail}
                        >
                          {(inviaEmail) => (
                            <form onSubmit={handleInviaEmail(inviaEmail)}>
                              <div className="input-group oggetto-field">
                                <label htmlFor="">Subject</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={configurazione.nome.toUpperCase()}
                                  disabled
                                />
                              </div>
                              <div className="input-group mb-3">
                                <textarea
                                  placeholder="Message"
                                  name="messaggio"
                                  value={inviaMail.messaggio}
                                  onChange={handleChangeMessaggio}
                                  className="form-control"
                                />
                              </div>

                              <div className="input-group">
                                <label>
                                  <input
                                    name="inviaUtenteFlag"
                                    type="checkbox"
                                    checked={inviaMail.inviaUtenteFlag}
                                    onChange={handleChangeMessaggio}
                                  />{' '}
                                  Send me a copy
                                </label>
                              </div>

                              <Button color="dark">Send request</Button>
                              {/* <button
                                className="btn btn-secondary"
                                type="submit"
                              >
                                Invia email
                              </button> */}
                            </form>
                          )}
                        </Mutation>
                      </Col>
                    </Row>
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({
  lookups: state.lookups,
  configurazione: getConfigurazione(state),
  configurazioneDaSalvare: getConfigurazioneDaSalvare(state),
  connessioniPendenti: getConnessioniPendentiByTipoLampadaId(state),
  connessionePendentiNome: getConnessionePendentiNomeById(state),
})

export default withRouter(
  connect(mapStateToProps, {
    //setConfigurazione,
    updateBase,
    updatePendente,
    updateAltezzaPendente,
    updateAltezzaMax,
    asyncRoundAltezzaPendente,
    updateNomeConfigurazione,
    calibraConfigurazione,
    updateHMinReference,
    updateHMaxReference,
  })(Configurazione)
)
