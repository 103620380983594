import gql from "graphql-tag";

const CONFIGURAZIONE_STANDARD = gql`
  query configurazioneStandard($tipoBaseId: String!) {
    configurazioneStandard(tipoBaseId: $tipoBaseId) {
      id
      nome
      isModificatoDefault
      base {
        id
        forma
        lunghezza
        larghezza
        tipoLampadaId
        tipoColoreBaseId
        tipoDimmerazioneId
        connessionePendentiId
        tipoDisposizione
        wattaggio
        tipiPendenti {
          id
          nome
          diametro
          tipiColorePendente {
            id
            nome
          }
        }
      }
      pendenti {
        progressivo
        tipoPendenteId
        x
        y
        z
        tipoColorePendenteId
      }
      codice
      hMinStandard
      hMaxStandard
    }
  }
`;

export default CONFIGURAZIONE_STANDARD;
