import gql from 'graphql-tag'

const LOOKUPS = gql`
  query lookups {
    tipiLampada(limit: 100, orderBy: ordine_ASC) {
      nodes {
        id
        nome
        urlImmagine
        coloreCavo
        lampadineTipo
        lampadineWatt
      }
    }
    tipiBase(limit: 100, orderBy: ordine_ASC) {
      nodes {
        id
        tipoLampadaId
        forma
        lunghezza
        larghezza
        descrizioneThumbnail
        tipoDisposizione
        wattaggio
      }
    }
    tipiColoreBase(limit: 100) {
      nodes {
        id
        nome
        hsl
        isDefault
      }
    }
    tipiDimmerazione(limit: 100) {
      nodes {
        id
        nome
        isDefault
      }
    }
    tipiPendente(limit: 100) {
      nodes {
        id
        nome
        diametro
        tipiColorePendente {
          id
        }
      }
    }
    tipiColorePendente(limit: 100) {
      nodes {
        id
        tipoPendenteId
        nome
        isDefault
      }
    }
    connessioniPendenti(limit: 100, orderBy: isDefault_DESC) {
      nodes {
        id
        nome
        tipoLampadaId
        isDefault
      }
    }
  }
`

export default LOOKUPS
