import gql from 'graphql-tag'

const ME = gql`
  query me {
    me {
      nome
      username
      email
      ruolo
    }
  }
`

export default ME
