import { combineReducers } from 'redux'

import configurazioneReducer from '../modules/configurazione'
import lookupsReducer from '../modules/lookups'

const rootReducer = combineReducers({
  lookups: lookupsReducer,
  configurazione: configurazioneReducer,
})

export default rootReducer
