import React, { useState } from 'react'

import { Mutation } from 'react-apollo'
import LOGIN from '../../../graphql/mutations/login'

import Layout from '../../shared/Layout/Layout'

import { Container, Form, FormGroup, Label, Button } from 'reactstrap'

import './Login.scss'

const Login = ({ history }) => {
  const [values, setValues] = useState({
    username: '',
    password: '',
  })

  const handleChangeValue = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  const handleLogin = login => event => {
    event.preventDefault()
    login({
      variables: {
        username: values.username,
        password: values.password,
      },
    })

    setValues({
      username: '',
      password: '',
    })
  }

  const handleLoggedIn = ({ login }) => {
    localStorage.setItem('catellani_token', login.token)
    history.push('/')
  }

  return (
    <Layout dark>
      <div className="login">
        <Container>
          <Mutation mutation={LOGIN} onCompleted={handleLoggedIn}>
            {(login, { error }) => (
              <Form onSubmit={handleLogin(login)}>
                <h5>Chandelier configurator</h5>
                <h3>Log in to your account</h3>

                <FormGroup>
                  <Label for="username">Username</Label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    value={values.username}
                    onChange={handleChangeValue}
                  />
                </FormGroup>

                <FormGroup className="password-field">
                  <Label for="password">Password</Label>

                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    value={values.password}
                    onChange={handleChangeValue}
                  />

                  {/* <Link to="/recupero-password">Dimenticata?</Link> */}
                </FormGroup>

                <Button type="submit" className="btn btn-secondary btn-block">
                  Log in
                </Button>
                {error && (
                  <p style={{ color: '#c00' }}>Wrong username or password</p>
                )}
              </Form>
            )}
          </Mutation>
          {/* <div className="or">
            <span>sei un nuovo cliente?</span>
          </div>

          <Link to="/registrazione">
            <Button type="submit" color="secondary" size="sm" block>
              Crea un account
            </Button>
          </Link> */}
        </Container>
      </div>
    </Layout>
  )
}

export default Login
