const distance3d = (item1, item2) => {
  const xDistance = item1.x - item2.x
  const yDistance = item1.y - item2.y
  const zDistance =
    item1.z - item1.tipoPendente.diametro / 2 - (item2.z - item2.tipoPendente.diametro / 2)
  const distance = Math.sqrt(
    Math.pow(xDistance, 2) + Math.pow(yDistance, 2) + Math.pow(zDistance, 2)
  )
  return distance
}

export const distance2d = (item1, item2) => {
  const xDistance = item1.x - item2.x
  const yDistance = item1.y - item2.y
  const distance = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2))
  return distance
}

const collisionDetection = (pendenti, pendenteMosso) => {
  let pendenteDragged
  let altriPendenti = []

  // RENDERE FUNZIONALE
  pendenti.forEach((pendente) => {
    const collisionWith = pendente.collisionWith || []

    if (pendente.progressivo === pendenteMosso.progressivo) {
      pendenteDragged = {
        ...pendente,
        ...pendenteMosso.fieldObject,
        tipoPendente: {
          ...pendente.tipoPendente,
          ...pendenteMosso.fieldObject,
        },
        collisionWith,
        collision: collisionWith.length > 0,
      }
    } else {
      const p = {
        ...pendente,
        collisionWith,
        collision: collisionWith.length > 0,
      }
      altriPendenti = [...altriPendenti, p]
    }
  })

  const otherItems = altriPendenti.map((pendente) => {
    const collidono =
      pendenteDragged.tipoPendenteId === 'SPL' || pendente.tipoPendenteId === 'SPL'
        ? false
        : pendente.tipoPendenteId === 'GM'
        ? // SE PENDENTE DI TIPO GOLD MOON ALLORA VERIFICARE CHE LA DISTANZA SIA SOLAMENTE A DUE DIMENSIONI
          pendente.z === pendenteDragged.z &&
          distance2d(pendente, pendenteDragged) <
            pendente.tipoPendente.diametro / 2 + pendenteDragged.tipoPendente.diametro / 2 + 2
        : // PENDENTE SFERICO QUINDI DISTANZA CONSIDERANDO 3 DIMENSIONI
          distance3d(pendente, pendenteDragged) <
          pendente.tipoPendente.diametro / 2 + pendenteDragged.tipoPendente.diametro / 2 + 2
    if (collidono) {
      // COLLIDONO
      if (!pendente.collisionWith.find((p) => p === pendenteDragged.progressivo)) {
        pendente.collisionWith.push(pendenteDragged.progressivo)
      }

      // const updateCollisionWith = pendente.collisionWith.filter(
      //   (id, index) => pendente.collisionWith.indexOf(id) === index,
      // )

      if (!pendenteDragged.collisionWith.find((p) => p === pendente.progressivo)) {
        pendenteDragged.collisionWith.push(pendente.progressivo)
      }

      // const updateCollisionWithPendenteDragged = pendenteDragged.collisionWith.filter(
      //   (id, index) => pendenteDragged.collisionWith.indexOf(id) === index,
      // )
      //pendenteDragged.collisionWith = updateCollisionWithPendenteDragged

      return pendente
    }

    // NON COLLIDONO
    // Rimuovi id del pendente dragged dalla lista collisionWith se i due pendenti non collidono

    // if (pendente.collisionWith.includes(pendenteDragged.progressivo)) {
    //   const removeIdIndex = pendente.collisionWith.indexOf(pendenteDragged.id)
    //   pendente.collisionWith.splice(removeIdIndex, 1)
    // }
    // // Rimuovi id del pendente dalla lista collisionWith del pendente dragged se i due pendenti non collidono
    // if (pendenteDragged.collisionWith.includes(pendente.id)) {
    //   const removeIdIndex = pendenteDragged.collisionWith.indexOf(pendente.id)
    //   pendenteDragged.collisionWith.splice(removeIdIndex, 1)
    // }

    pendente.collisionWith = pendente.collisionWith.filter((p) => p !== pendenteDragged.progressivo)
    pendenteDragged.collisionWith = pendenteDragged.collisionWith.filter(
      (p) => p !== pendente.progressivo
    )

    return pendente
  })

  return [...otherItems, pendenteDragged]
}

export default collisionDetection
