import React from 'react'
import { connect } from 'react-redux'
import Draggable from 'react-draggable'

import {
  updateAltezzaPendente,
  updateBase,
  asyncRoundAltezzaPendente,
} from '../../../redux/modules/configurazione'

import getSvgPendente from '../../../lib/svgPendente'

import './Pendente.css'

const getColoreHslaCavo = (colore) => {
  switch (colore) {
    case 'black':
      return 'hsla(0,0%,0%,0.4)'
    case 'blue':
      return 'hsla(210, 60%, 33%, 0.5)'
    default:
      return 'hsla(0,0%,90%,0.6)' //grigio
  }
}

const Pendente = ({
  pendente,
  proiezione = 'z',
  coloreCavo = 'grey',
  scale,
  updateAltezzaPendente,
  updateBase,
  asyncRoundAltezzaPendente,
  hMin,
  hMax,
  pdf,
}) => {
  const diametro = pendente.tipoPendente.diametro

  const handleDrag = (e, position) => {
    updateAltezzaPendente({
      progressivo: pendente.progressivo,
      z: Math.round(position.y),
    })
  }

  const handleDragStop = () => {
    updateBase({ activePendente: null })
    asyncRoundAltezzaPendente(pendente.progressivo)
  }

  const renderPendenteXy = (proiezione) => {
    return (
      <Draggable
        axis="y"
        handle=".handle"
        bounds={{
          left: 0,
          top: hMin,
          right: 0,
          bottom: hMax,
        }}
        position={{
          x: pendente[proiezione === 'x' ? 'y' : 'x'],
          y: pendente.z,
        }}
        grid={[1, 1]}
        scale={scale} // 665 / hMax
        onDrag={handleDrag}
        onStop={handleDragStop}
      >
        <g>
          <line
            x1={0}
            x2={0}
            y1="-1000"
            y2={0}
            stroke={getColoreHslaCavo(coloreCavo)}
            strokeWidth={
              pendente.tipoPendenteId === 'JO' ||
              pendente.tipoPendenteId === 'GM' ||
              pendente.tipoPendenteId === 'SPL' ||
              pendente.tipoPendenteId === 'P188' ||
              pendente.tipoPendenteId === 'P1816'
                ? 0.1
                : 0.7
            }
            strokeLinecap="butt"
          />

          <image
            xlinkHref={getSvgPendente(
              pendente,
              pendente.tipoColorePendenteId,
              proiezione
            )}
            width={diametro}
            height={
              pendente.tipoPendenteId.startsWith('P18')
                ? diametro + 5
                : diametro
            }
            x={-diametro / 2}
            y={
              pendente.tipoPendenteId.startsWith('P18')
                ? -(diametro + 5)
                : pendente.tipoPendenteId === 'GM'
                ? (-diametro * 3) / 4
                : -diametro
            }
            //className="handle"
          />
          <circle
            cx={0}
            cy={
              pendente.tipoPendenteId === 'GM' ? -diametro / 4 : -diametro / 2
            }
            r={diametro / 2 + 1}
            fill="transparent"
            className="handle"
            stroke={
              pendente.collision ? '#c00' : pendente.shadow ? '#da0' : null
            }
            strokeWidth={0.7}
            strokeDasharray="3 1"
          />

          {/* <text
            x={2}
            y={-diametro - 1}
            textAnchor="middle"
            alignmentBaseline="middle"
            className="handle"
            cursor="default"
            style={{ fontSize: '.3rem' }}
          >
            {pendente.progressivo}
          </text> */}
        </g>
      </Draggable>
    )
  }

  switch (proiezione) {
    case 'z':
      return (
        <React.Fragment>
          {pdf ? (
            <React.Fragment>
              <circle cx={pendente.x} cy={pendente.y} r=".5" />
              <text
                fontFamily="Helvetica, Arial, sans-serif"
                x={pendente.x}
                y={pendente.y - 3}
                textAnchor="middle"
                alignmentBaseline="middle"
                cursor="default"
                className="handle"
                style={{ fontSize: '.2rem' }}
              >
                {pendente.progressivo}
              </text>
            </React.Fragment>
          ) : (
            <image
              xlinkHref={getSvgPendente(
                pendente,
                pendente.tipoColorePendenteId,
                proiezione
              )}
              width={diametro}
              height={diametro}
              x={pendente.x - diametro / 2}
              y={pendente.y - diametro / 2}
            />
          )}
        </React.Fragment>
      )

    default:
      return renderPendenteXy(proiezione)
  }
}

export default connect(null, {
  updateAltezzaPendente,
  updateBase,
  asyncRoundAltezzaPendente,
})(Pendente)
