import { svgAsPngUri } from 'save-svg-as-png'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from './vfs_fonts'
import logo from '../components/shared/logo_data_uri'

pdfMake.vfs = pdfFonts.pdfMake.vfs

pdfMake.fonts = {
  Helvetica: {
    normal: 'Helvetica.ttf',
  },
}

const creaPdf = async (configurazione, dimensioni, connessionePendentiNome) => {
  const { base, pendenti } = configurazione

  const proiezioneZ = await svgAsPngUri(document.getElementById('svg_z'), {
    scale: 10,
  })

  const proiezioneY = await svgAsPngUri(document.getElementById('svg_y'), {
    scale: 10,
  })

  const proiezioneX = await svgAsPngUri(document.getElementById('svg_x'), {
    scale: 10,
  })

  // ${configurazione.modificato ? '(modificato)' : ''}\n
  const baseDettagli = `${base.id} ${configurazione.modificato ? '(modificato)' : ''}\n ${base.tipoDisposizione}\n ${
    base.forma
  } ${
    base.forma === 'Round' ? `ø ${base.lunghezza}` : `${base.lunghezza} x ${base.larghezza}`
  } cm \n ${base.tipoColoreBase.nome}`

  const tipiPendentiDisponibili = configurazione.base.numeroPendentiPerTipologia
    .map(
      (t) =>
        `${t.numero} x ${t.tipoPendenteId} ${t.nomePendente} ${
          t.tipoPendenteId !== 'JO' && t.tipoPendenteId !== 'SPL' ? `ø ${t.diametro} cm ` : ''
        }(${t.nomeColore.toLowerCase()})`
    )
    // ${
    //   base.forma === 'Round' ? `ø ${base.lunghezza}` : `${base.lunghezza} x ${base.larghezza}`
    // } cm
    .join('\n')

  const wattWithDot = base.lampada.lampadineWatt.toString()
  const lampadineWatt = wattWithDot.replace('.', ',')

  const lampadine = `${pendenti.length} x ${lampadineWatt} W ${base.lampada.lampadineTipo}`

  const dimmerazione = base.tipoDimmerazione.nome

  const coloreCavo = base.lampada.coloreCavo

  const intestazioneTabella = [
    'Pendant Ref.',
    'H from ceiling',
    'H from base',
    'Type',
    'Colour',
  ].map((col) => {
    return {
      text: col,
      fontSize: 9,
    }
  })

  const datiTabella = pendenti.map((pendente) => {
    return [
      pendente.progressivo,
      pendente.z + ' cm',
      pendente.z - 5 + ' cm',
      pendente.tipoPendenteId,
      pendente.tipoColorePendente.nome,
    ]
  })

  const pendentiTabella = [intestazioneTabella, ...datiTabella]

  const collisionePendenti = configurazione.atLeastOneCollision
    ? 'Some pendants are too close to each other'
    : ''

  const docDefinition = {
    defaultStyle: {
      font: 'Helvetica',
    },
    pageMargins: [40, 100, 40, 70],
    pageSize: 'A4',
    header: function () {
      return [
        {
          columns: [
            {
              stack: [{ image: logo, width: 75 }],
              margin: [40, 30, 0, 0],
              width: 120,
            },
            {
              text: `${configurazione.base.lampada.nome}: ${configurazione.codice} - ${configurazione.nome}`,
              fontSize: 13,
              alignment: 'right',
              margin: [0, 52, 40, 0],
            },
          ],
          columnGap: 10,
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 30,
              y1: 15,
              x2: 595.28 - 30,
              y2: 15,
              lineWidth: 0.5,
              color: '#ddd',
            },
          ],
        },
      ]
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          canvas: [
            {
              type: 'line',
              x1: 30,
              y1: 15,
              x2: 595.28 - 30,
              y2: 15,
              lineWidth: 0.5,
              color: '#ddd',
            },
          ],
        },
        {
          columns: [
            {
              text: `Catellani & Smith reserves the right to make any technical changes, for aesthetic or structural reasons, which may be necessary during manufacturing.`,
              absolutePosition: { x: 40, y: 30 },
              fontSize: 7,
            },
            {
              text: currentPage.toString() + ' of ' + pageCount,
              absolutePosition: { x: 595.28 - 40 - 20, y: 30 },
              fontSize: 7,
            },
          ],
          columnGap: 10,
        },
      ]
    },
    content: [
      {
        layout: 'catellani',
        style: 'tabella1',
        table: {
          headerRows: 1,
          widths: ['auto', '*'],
          body: [
            [
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: '\nBASE',
                border: [false, false, false, true],
                margin: [0, 3, 0, 1],
                fontSize: 10,
              },
              {
                text: baseDettagli,
                border: [false, false, false, true],
                margin: [0, 3, 0, 1],
                fontSize: 13,
              },
            ],
            [
              { text: 'MAX HEIGHT', margin: [0, 3, 0, 1], fontSize: 10 },
              { text: configurazione.hMax + ' cm', margin: [0, 3, 0, 1], fontSize: 13 },
            ],
            [
              { text: 'PENDANTS', margin: [0, 3, 0, 1], fontSize: 10 },
              { text: tipiPendentiDisponibili, margin: [0, 3, 0, 1], fontSize: 13 },
            ],
            // [{ text: 'PENDANTS CONNECTION', margin: [0, 3, 0, 1] }, { text: connessionePendentiNome, margin: [0, 3, 0, 1] }],
            [
              { text: 'LIGHT SOURCE', margin: [0, 3, 0, 1], fontSize: 10 },
              { text: lampadine, margin: [0, 3, 0, 1], fontSize: 13 },
            ],
            [
              { text: 'COMPATIBLE DIMMING', margin: [0, 3, 0, 1], fontSize: 10 },
              { text: dimmerazione, margin: [0, 3, 0, 1], fontSize: 13 },
            ],
            [
              { text: 'CABLE COLOUR', margin: [0, 3, 0, 1], fontSize: 10 },
              { text: coloreCavo, margin: [0, 3, 0, 1], fontSize: 13 },
            ],
            [
              {
                text: collisionePendenti,
                color: 'red',
                fontSize: 10,
                colSpan: 2,
              },
            ],
          ],
        },
        margin: [0, 0, 0, 20],
      },

      {
        alignment: 'center',
        margin: [0, 0, 0, 5],
        stack: [
          {
            image: proiezioneZ,
            fit: [500, 450],
          },
        ],
      },
      {
        // Manda su una nuova pagine proiezioni x e y e loro didascalie quando
        // la lunghezza della lista tipologia pendenti (tabella 1) è maggiore di 2
        // pageBreak: configurazione.base.numeroPendentiPerTipologia.length > 5 ? 'before' : 'before',


        //Manda su una nuova pagina sempre
        pageBreak: 'before',
        columns: [
          {
            alignment: 'center',
            margin: [0, 10, 0, 0],
            width: '50%',
            stack: [
              {
                text: 'FRONT VIEW',
                style: 'proiezione',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: -5,
                    y1: 0,
                    x2: 595.28 / 2 - 40,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#999',
                  },
                ],
              },
              {
                fit: [595.28 / 2, 600],
                image: proiezioneY,
                margin: [0, 2, 0, 0],
              },
            ],
          },
          {
            alignment: 'center',
            margin: [0, 10, 0, 0],
            width: '50%',
            stack: [
              {
                text: 'SIDE VIEW',
                style: 'proiezione',
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 595.28 / 2 - 20,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#999',
                  },
                ],
              },
              {
                fit: [595.28 / 2, 600],
                image: proiezioneX,
                margin: [0, 2, 0, 0],
                pageBreak: 'after',
              },
            ],
          },
        ],
      },
      {
        layout: 'catellani',
        style: 'tabella2',
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*'],
          body: pendentiTabella,
        },
      },
    ],
    styles: {
      footer: {
        margin: 40,
      },
      tabella1: {
        fontSize: 9,
        hLineColor: '#ddd',
        hLineWidth: 1,
      },
      tabella2: {
        fontSize: 8,
      },
      proiezione: {
        margin: 5,
        fontSize: 11,
      },
    },
  }

  pdfMake.tableLayouts = {
    catellani: {
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 0
        }
        return i === node.table.headerRows ? 0.5 : 0.3
      },
      vLineWidth: function (i) {
        return 0
      },
      hLineColor: function (i) {
        return i === 1 ? 'black' : '#ddd'
      },
    },
  }
  pdfMake.createPdf(docDefinition).download()
}

export default creaPdf
