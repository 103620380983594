import gql from 'graphql-tag'

const INVIA_EMAIL = gql`
  mutation inviaEmail(
    $configurazioneId: Int!
    $messaggio: String!
    $inviaUtenteFlag: Boolean!
  ) {
    inviaEmail(
      configurazioneId: $configurazioneId
      messaggio: $messaggio
      inviaUtenteFlag: $inviaUtenteFlag
    )
  }
`
export default INVIA_EMAIL
