import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import './Layout.scss'

const Layout = ({ dark = false, isLoggedIn = false, children }) => {
  return (
    <div className={`layout ${dark ? 'dark' : ''}`}>
      <Header isLoggedIn={isLoggedIn} />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
