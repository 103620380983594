// ------------------------------------
// Constants
// ------------------------------------
const SET_LOOKUPS = 'catellanismith/lookups/SET_LOOKUPS'

// ------------------------------------
// Actions
// ------------------------------------

export function setLookups(lookups) {
  return {
    type: SET_LOOKUPS,
    payload: lookups,
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  tipiLampada: [],
  tipiBase: [],
  tipiColoreBase: [],
  tipiDimmerazione: [],
  tipiPendente: [],
  tipiColorePendente: [],
  connessioniPendenti: []
}

export default function lookupsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOOKUPS:
      const data = action.payload

      return {
        tipiLampada: data.tipiLampada.nodes,
        tipiBase: data.tipiBase.nodes,
        tipiColoreBase: data.tipiColoreBase.nodes,
        tipiDimmerazione: data.tipiDimmerazione.nodes,
        tipiPendente: data.tipiPendente.nodes,
        tipiColorePendente: data.tipiColorePendente.nodes,
        connessioniPendenti: data.connessioniPendenti.nodes
      }

    default:
      return state
  }
}

// ------------------------------------
// Selector
// ------------------------------------

export const getTipiBaseByLampadaId = (globalState, tipoLampadaId) => {
  const tipiBase = globalState.lookups.tipiBase
  return tipiBase ? tipiBase.filter(b => b.tipoLampadaId === tipoLampadaId) : []
}

export const getLampadaByLampadaId = (globalState, tipoLampadaId) => {
  const tipiLampada = globalState.lookups.tipiLampada
  const lampada = tipiLampada
    ? tipiLampada.find(lampada => lampada.id === tipoLampadaId)
    : []
  return lampada
}

// export const getTipiColorePendenteByTipoPendente = (globalState, tipoPendenteId) => {
//   const tipiColoriPendente = globalState.lookups.tipiColoriPendente
//   return tipiColoriPendente ? tipiColoriPendente.filter(t => t.tipoPendenteId === tipoPendenteId) : []
// }

export const getConnessioniPendentiByTipoLampadaId = globalState => {
  const tipoLampadaId = globalState.configurazione.base.tipoLampadaId
  return globalState.lookups.connessioniPendenti.filter(connessione => connessione.tipoLampadaId === tipoLampadaId)
}

export const getConnessionePendentiNomeById = globalState => {
  const connessionePendentiId = globalState.configurazione.base.connessionePendentiId

  return globalState.lookups.connessioniPendenti.find(c => c.id === connessionePendentiId).nome
}