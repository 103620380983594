import gql from 'graphql-tag'

const ELIMINA_CONFIGURAZIONE = gql`
  mutation eliminaConfigurazione($id: Int!) {
    deleteConfigurazione(id: $id) {
      nome
    }
  }
`
export default ELIMINA_CONFIGURAZIONE
