import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

// Redux
import { setLookups } from './redux/modules/lookups'

// Components
import ConfigurazioniSalvate from './components/routes/ConfigurazioniSalvate/ConfigurazioniSalvate'
import SceltaLampada from './components/routes/SceltaLampada/SceltaLampada'
import SceltaBase from './components/routes/SceltaBase/SceltaBase'
import ConfigurazioneStandard from './components/routes/ConfigurazioneStandard/ConfigurazioneStandard'
import ConfigurazioneSalvata from './components/routes/ConfigurazioneSalvata/ConfigurazioneSalvata'

const Routes = ({ lookups, setLookups }) => {
  useEffect(() => {
    setLookups(lookups)
  }, [lookups, setLookups])

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={SceltaLampada} />
        <Route exact path="/configurazioni/nuova" component={SceltaLampada} />
        <Route
          exact
          path="/configurazioni/nuova/:tipoLampadaId"
          component={SceltaBase}
        />
        <Route
          path="/configurazioni/nuova/:tipoLampadaId/:tipoBaseId"
          component={ConfigurazioneStandard}
        />
        <Route exact path="/configurazioni" component={ConfigurazioniSalvate} />
        <Route
          path="/configurazioni/:configurazioneId"
          component={ConfigurazioneSalvata}
        />
      </Switch>
    </React.Fragment>
  )
}

export default connect(
  null,
  { setLookups },
)(Routes)
