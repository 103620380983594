import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Query, Mutation } from 'react-apollo'
import CONFIGURAZIONE_SALVATA from '../../../graphql/queries/configurazioneSalvata'
import AGGIORNA_CONFIGURAZIONE from '../../../graphql/mutations/aggiornaConfigurazione'

import { getConfigurazione, setConfigurazione } from '../../../redux/modules/configurazione'

import Layout from '../../shared/Layout/Layout'
import Configurazione from '../../shared/Configurazione/Configurazione'

const ConfigurazioneSalvata = ({ match, setConfigurazione, configurazione }) => {
  const configurazioneId = parseInt(match.params.configurazioneId, 10)

  const [backup, setBackup] = useState({})

  const handleCompletedConfigurazione = (data) => {
    const configurazione = {
      ...data.configurazione,
      isSalvata: true,
    }

    setConfigurazione(configurazione)
    setBackup(configurazione)
  }

  const handleReset = () => {
    setConfigurazione(backup)
  }

  return (
    <Layout
      isLoggedIn
      title={configurazione.nome}
      subtitle={`${configurazione.base.lampada && configurazione.base.lampada.nome} - ${
        configurazione.base.id
      }`}
      thumbnail={configurazione.base.lampada && configurazione.base.lampada.urlImmagine}
    >
      <div className="configurazione-standard">
        <Query
          query={CONFIGURAZIONE_SALVATA}
          variables={{ configurazioneId }}
          onCompleted={handleCompletedConfigurazione}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <span>Loading...</span>
            }

            if (error) {
              return <span>Error</span>
            }
            return (
              <Mutation mutation={AGGIORNA_CONFIGURAZIONE}>
                {(aggiornaConfigurazione) => (
                  <Configurazione
                    backup={backup}
                    onSave={aggiornaConfigurazione}
                    onReset={handleReset}
                  />
                )}
              </Mutation>
            )
          }}
        </Query>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  configurazione: getConfigurazione(state),
})

export default connect(mapStateToProps, {
  setConfigurazione,
})(ConfigurazioneSalvata)
