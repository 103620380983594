import React from 'react'
import { connect } from 'react-redux'
import { isUnPendente35cm } from '../../../redux/modules/configurazione'

// Components
// Pendente component visto dall'alto
import Pendente from '../Pendente/Pendente'

const getPendentiOrdinati = (pendenti, proiezione) => {
  const copiaPendenti = [...pendenti]
  return copiaPendenti.sort((p1, p2) => p1[proiezione] - p2[proiezione])
}

// const colorTextOnBase = coloreBase => {
//   const match = coloreBase.toLowerCase().match(/(nero|richiesta)/i)
//   return match ? 'white' : 'black'
// }

const Base = ({ configurazione, proiezione = 'z', pdf = false, scale, style, showLineaMinimo }) => {
  const { base, pendenti } = configurazione

  const H_MAX = configurazione.hMax
  const fontSize = 5

  let forma = null
  let width = 0
  let height = 0
  let viewBox = '0 0 10 10'
  let pendentiOrdinati = null

  // Costanti
  const H_BASE = 5
  const H_MIN = 35
  //const H_MAX = 125

  switch (base.forma.toLowerCase()) {
    case 'squared':
    case 'rectangular':
      switch (proiezione) {
        case 'z':
          width = base.lunghezza
          height = base.larghezza
          viewBox = `0 0 ${width + 20} ${height + 20}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'z')
          forma = (
            <rect
              x="0"
              y="0"
              width={width}
              height={height}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        case 'x':
          width = base.larghezza
          height = H_BASE
          viewBox = `0 0 ${width + 40} ${H_BASE + H_MAX + 10}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'x')
          forma = (
            <rect
              x="0"
              y="0"
              width={width}
              height={height}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        case 'y':
          width = base.lunghezza
          height = H_BASE
          viewBox = `0 0 ${width + 40} ${H_BASE + H_MAX + 10}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'y')
          forma = (
            <rect
              x="0"
              y="0"
              width={width}
              height={height}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        default:
          break
      }
      break
    case 'round':
      switch (proiezione) {
        case 'z':
          width = base.lunghezza
          height = base.lunghezza
          viewBox = `0 0 ${width + 20} ${height + 20}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'z')
          forma = (
            <circle
              cx={width / 2}
              cy={height / 2}
              r={width / 2}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        case 'x':
          width = base.lunghezza
          height = H_BASE
          viewBox = `0 0 ${width + 40} ${H_BASE + H_MAX + 10}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'x')
          forma = (
            <rect
              x="0"
              y="0"
              width={width}
              height={H_BASE}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        case 'y':
          width = base.lunghezza
          height = H_BASE
          viewBox = `0 0 ${width + 40} ${H_BASE + H_MAX + 10}`
          pendentiOrdinati = getPendentiOrdinati(pendenti, 'y')
          forma = (
            <rect
              x="0"
              y="0"
              width={width}
              height={H_BASE}
              fill={base.tipoColoreBase.hsl}
              stroke="hsl(0, 0%, 80%)"
              strokeWidth={base.tipoColoreBase.hsl === 'hsl(0,0%,100%)' ? '0.2' : '0'}
            />
          )
          break
        default:
          break
      }
      break
    default:
      break
  }

  return (
    <svg
      id={`svg_${proiezione}`}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMin meet"
      style={style}
      // width={responsive ? null : width * 3}
    >
      <g transform={proiezione === 'z' ? 'translate(10, 10)' : 'translate(10)'}>
        {proiezione === 'z' && forma}
        {proiezione !== 'z' && (
          <React.Fragment>
            {showLineaMinimo && (
              <g id="linea-minimo">
                <line // linea limite minimo
                  x1="0"
                  y1="35"
                  x2={width}
                  y2="35"
                  stroke="hsl(202, 22%, 70%)"
                  strokeWidth="0.2"
                  strokeDasharray="1"
                />
                <text
                  fontFamily="Helvetica, Arial, sans-serif"
                  x={width + 2}
                  y="35"
                  dominantBaseline="baseline"
                  fontSize={fontSize}
                  fill="#0069d2"
                >
                  35 cm
                </text>
              </g>
            )}
            <line // linea limite massimo
              x1="0"
              y1={H_MAX}
              x2={width}
              y2={H_MAX}
              stroke="hsl(202, 22%, 70%)"
              strokeWidth="0.2"
              strokeDasharray="1"
            />
            <text
              fontFamily="Helvetica, Arial, sans-serif"
              x={width + 2}
              y={H_MAX}
              dominantBaseline="baseline"
              fill="#0069d2"
              fontSize={fontSize}
            >
              {H_MAX} cm
            </text>
          </React.Fragment>
        )}

        {pendentiOrdinati &&
          pendentiOrdinati.map((pendente) => (
            <Pendente
              key={pendente.progressivo}
              pendente={pendente}
              hMin={H_MIN}
              hMax={H_MAX}
              scale={scale}
              proiezione={proiezione}
              coloreCavo={base.lampada.coloreCavo}
              pdf={pdf}
            />
          ))}
        {proiezione !== 'z' && forma}
        {proiezione !== 'z' && (
          <text
            fontFamily="Helvetica, Arial, sans-serif"
            x={width + 2}
            y={height}
            dominantBaseline="baseline"
            fontSize={fontSize}
            fill="#888"
          >
            H 5 cm
          </text>
        )}
      </g>
    </svg>
  )
}

const mapStateToProps = (state) => {
  return {
    showLineaMinimo: isUnPendente35cm(state),
    hMaxGm: state.configurazione.hMaxGm,
    hMinSPL: state.configurazione.hMinSPL,
  }
}

export default connect(mapStateToProps, { isUnPendente35cm })(Base)
