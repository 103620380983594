import gql from "graphql-tag";

const CONFIGURAZIONI_SALVATE = gql`
  query configurazioni($keywords: String) {
    configurazioni(limit: 100, keywords: $keywords, orderBy: data_DESC) {
      nodes {
        id
        nome
        data
        codice
        hMax
        numeroPendenti
        tipoBase {
          forma
          lunghezza
          larghezza
          tipoDisposizione
          tipoLampada {
            nome
          }
        }
        utente {
          nome
        }
      }
    }
  }
`;

export default CONFIGURAZIONI_SALVATE;
