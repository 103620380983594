import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { FaPlus, FaMinus } from 'react-icons/fa'

import './Accordion.scss'

const Accordion = ({ heading, children }) => {
  const [isOpen, setOpen] = useState(true)

  const toggle = () => {
    setOpen(!isOpen)
  }

  return (
    <div className="accordion">
      <div
        className={`accordion-heading ${isOpen ? 'open' : ''}`}
        onClick={toggle}
      >
        <h5>{heading}</h5>
        {isOpen ? <FaMinus /> : <FaPlus />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="accordion-body">{children}</div>
      </Collapse>
    </div>
  )
}

export default Accordion
