import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { Query } from 'react-apollo'
import moment from 'moment'
import 'moment/locale/it'

import createStore from './redux/store/createStore'
import { GRAPHQL_API_URI } from './config'
import LOOKUPS from './graphql/queries/lookups'

// Components
import Login from './components/routes/Login/Login'
import Routes from './Routes'

// Styles
import './App.scss'

//MOMENT: SET LOCALE
moment.locale('it')

// REDUX STORE
const reduxStore = createStore()

// APOLLO CLIENT
const apolloClient = new ApolloClient({
  uri: GRAPHQL_API_URI,
  request: async operation => {
    const token = localStorage.getItem('catellani_token')
    operation.setContext({
      headers: token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {},
    })
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors)
    }
    if (networkError) {
      console.log('Network error', networkError)
    }
  },
})

apolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'cache-and-network',
  },
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <ApolloProvider client={apolloClient}>
          <ApolloHooksProvider client={apolloClient}>
            <Provider store={reduxStore}>
              <Router>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Query
                    query={LOOKUPS}
                    // fetchPolicy="cache-first"
                    //skip={!localStorage.getItem('catellani_token')}
                  >
                    {({ data, loading, error }) => {
                      if (loading) {
                        return <div>Loading...</div>
                      }

                      if (error) {
                        // Probabilmente non loggato
                        return <Redirect to="/login" />
                      }

                      return (
                        <Route
                          path="/"
                          render={() => <Routes lookups={data} />}
                        />
                      )
                    }}
                  </Query>
                </Switch>
              </Router>
            </Provider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </div>
    )
  }
}

export default App
