import collisionDetection from '../../lib/collisionDetection'
import shadowDetection from '../../lib/shadowDetection'

// ------------------------------------
// Utility
// ------------------------------------
const roundToNearestMultipleOf = (multiple) => (number) => {
  return Math.round(number / multiple) * multiple
}
export const altezzeMinMax = (pendenti) => {
  const altezze = pendenti.map((pendente) => pendente.z)

  const altezzeMinMax = altezze.reduce(
    (acc, item) => {
      let min = acc.min
      let max = acc.max

      if (item < min) {
        min = item
      }

      if (item > max) {
        max = item
      }

      return { min, max }
    },
    { min: Infinity, max: -Infinity }
  )

  return altezzeMinMax
}

// ------------------------------------
// Constants
// ------------------------------------
const SET_CONFIGURAZIONE = 'catellanismith/configurazione/SET_CONFIGURAZIONE'
const UPDATE_BASE = 'catellanismith/configurazione/UPDATE_BASE'
const UPDATE_PENDENTE = 'catellanismith/configurazione/UPDATE_PENDENTE'
const UPDATE_ALTEZZA_PENDENTE =
  'catellanismith/configurazione/UPDATE_ALTEZZA_PENDENTE'
const ROUND_ALTEZZA_PENDENTE =
  'catellanismith/configurazione/ROUND_ALTEZZA_PENDENTE'
const UPDATE_ALTEZZA_MAX = 'catellanismith/configurazione/UPDATE_ALTEZZA_MAX'
const UPDATE_NOME_CONFIGURAZIONE =
  'catellanismith/configurazione/UPDATE_NOME_CONFIGURAZIONE'
const RICALIBRA_PENDENTI = 'catellanismith/configurazione/RICALIBRA_PENDENTI'
const UPDATE_MIN_H_REFERENCE =
  'catellanismith/configurazione/UPDATE_MIN_H_REFERENCE'
const UPDATE_MAX_H_REFERENCE =
  'catellanismith/configurazione/UPDATE_MAX_H_REFERENCE'

// ------------------------------------
// Actions
// ------------------------------------

export function setConfigurazione(configurazione) {
  return {
    type: SET_CONFIGURAZIONE,
    payload: configurazione,
  }
}

export function updateBase(fieldObject) {
  return {
    type: UPDATE_BASE,
    payload: fieldObject,
  }
}

export function updatePendente(pendente) {
  return {
    type: UPDATE_PENDENTE,
    payload: pendente,
  }
}

export function updateAltezzaPendente(pendente) {
  return {
    type: UPDATE_ALTEZZA_PENDENTE,
    payload: pendente,
    meta: {
      throttle: true,
    },
  }
}

function roundAltezzaPendente(progressivo) {
  return {
    type: ROUND_ALTEZZA_PENDENTE,
    payload: progressivo,
  }
}

export function asyncRoundAltezzaPendente(progressivo) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(roundAltezzaPendente(progressivo))
      dispatch(updateBase({ activePendente: null }))
    }, 120)
  }
}

export function updateNomeConfigurazione(nome) {
  return {
    type: UPDATE_NOME_CONFIGURAZIONE,
    payload: nome,
  }
}

export function updateAltezzaMax(value) {
  return {
    type: UPDATE_ALTEZZA_MAX,
    payload: value,
  }
}

export function calibraConfigurazione(
  altezzaNuovaConfigurazione,
  pendentiStandartCatellani,
  configurazione
) {
  const altezzeMinMaxStandardCatellani = altezzeMinMax(
    pendentiStandartCatellani
  )
  const arrayConAltezzeRicalibrate = pendentiStandartCatellani.map(
    (pendente) => {
      const zRelativa = pendente.z - altezzeMinMaxStandardCatellani.min
      const nuovaZRelativa =
        zRelativa *
        (altezzaNuovaConfigurazione /
          (altezzeMinMaxStandardCatellani.max -
            altezzeMinMaxStandardCatellani.min))
      const nuovaZAssoluta = roundToNearestMultipleOf(5)(
        nuovaZRelativa + configurazione.hMinReference
      )
      return {
        ...pendente,
        z: nuovaZAssoluta,
      }
    }
  )
  return {
    type: RICALIBRA_PENDENTI,
    payload: arrayConAltezzeRicalibrate,
  }
}
export function updateHMinReference(value) {
  return {
    type: UPDATE_MIN_H_REFERENCE,
    payload: roundToNearestMultipleOf(5)(value),
  }
}

export function updateHMaxReference(value) {
  return {
    type: UPDATE_MAX_H_REFERENCE,
    payload: roundToNearestMultipleOf(5)(value),
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  base: {
    lampada: {},
    tipiPendenti: [],
  },
  pendenti: [],
}

export default function configurazioneReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURAZIONE:
      const pendentiStatoIniziale = action.payload.pendenti.map((pendente) => ({
        ...pendente,
        verso: Math.floor(Math.random() * 2),
      }))

      // Calcolo altezza massima
      // prima delle modifiche
      const altezzePendenti = action.payload.pendenti.map((p) => p.z)

      const hMaxIniziale =
        altezzePendenti.length > 0 ? Math.max(...altezzePendenti) : 0

      return {
        ...state,
        ...action.payload,
        pendenti: pendentiStatoIniziale,
        hMaxIniziale,
        hMax: hMaxIniziale,
        hMinReference: null,
        hMaxReference: null,
      }

    case UPDATE_BASE:
      return {
        ...state,
        isModificatoDefault: true,
        base: {
          ...state.base,
          ...action.payload,
        },
      }

    case UPDATE_PENDENTE:
    case UPDATE_ALTEZZA_PENDENTE:
      const pendenti = state.pendenti.map((p) => {
        if (p.progressivo === action.payload.progressivo) {
          return {
            ...p,
            ...action.payload,
          }
        }
        return p
      })
      const altezze = altezzeMinMax(pendenti)

      const min = roundToNearestMultipleOf(5)(altezze.min)
      const max = roundToNearestMultipleOf(5)(altezze.max)

      return {
        ...state,
        isModificatoDefault: true,
        pendenti,
        base: {
          ...state.base,
          activePendente:
            action.type === UPDATE_ALTEZZA_PENDENTE
              ? action.payload.progressivo
              : null,
        },
        hMinReference: min,
        hMaxReference: max,
      }

    case ROUND_ALTEZZA_PENDENTE:
      return {
        ...state,

        pendenti: state.pendenti.map((pendente) => {
          if (pendente.progressivo === action.payload) {
            return {
              ...pendente,
              z: roundToNearestMultipleOf(5)(pendente.z),
            }
          }
          return pendente
        }),
      }
    case UPDATE_ALTEZZA_MAX:
      const newHmax = roundToNearestMultipleOf(5)(action.payload)

      return {
        ...state,
        previousHMax: state.hMax,
        hMax: newHmax,
        hMaxReference: newHmax,
        hMinReference:
          state.hMinReference && state.hMaxReference
            ? newHmax - (state.hMaxReference - state.hMinReference) < 35
              ? 35
              : newHmax - (state.hMaxReference - state.hMinReference)
            : newHmax - (state.hMaxStandard - state.hMinStandard) < 35
            ? 35
            : newHmax - (state.hMaxStandard - state.hMinStandard),
        pendenti: state.pendenti.map((pendente) => {
          const nuovaAltezzaPendente = pendente.z + newHmax - state.hMax

          return {
            ...pendente,
            z: nuovaAltezzaPendente > 35 ? nuovaAltezzaPendente : 35,
          }
        }),
      }
    case UPDATE_NOME_CONFIGURAZIONE:
      return {
        ...state,
        nome: action.payload,
      }
    case RICALIBRA_PENDENTI:
      return {
        ...state,
        pendenti: action.payload,
      }
    case UPDATE_MIN_H_REFERENCE:
      return {
        ...state,
        hMinReference: action.payload,
      }
    case UPDATE_MAX_H_REFERENCE:
      return {
        ...state,
        hMaxReference: action.payload,
      }

    default:
      return state
  }
}

// ------------------------------------
// Selector
// ------------------------------------

export const getConfigurazione = (globalState) => {
  const configurazione = globalState.configurazione
  const lookups = globalState.lookups

  // Estraggo dati lampadina da TipoLampada
  const lampada = lookups.tipiLampada.find(
    (tl) => tl.id === configurazione.base.tipoLampadaId
  )

  // Estraggo nome dimmerazione da Dimmerazione
  const tipoDimmerazione = lookups.tipiDimmerazione.find(
    (td) => td.id === configurazione.base.tipoDimmerazioneId
  )

  // Estraggo nome colore base da Colore base
  const tipoColoreBase = lookups.tipiColoreBase.find(
    (tcb) => tcb.id === configurazione.base.tipoColoreBaseId
  )

  // // Calcolo min / max altezze pendenti
  // const altezze = configurazione.pendenti.map((pendente) => pendente.z)

  // const altezzeMinMax = altezze.reduce(
  //   (acc, item) => {
  //     let min = acc.min
  //     let max = acc.max

  //     if (item < min) {
  //       min = item
  //     }

  //     if (item > max) {
  //       max = item
  //     }

  //     return { min, max }
  //   },
  //   { min: Infinity, max: -Infinity }
  // )

  const altezzeMinMaxPrimaRender = altezzeMinMax(configurazione.pendenti)
  const hMinReference =
    configurazione.hMinReference || altezzeMinMaxPrimaRender.min
  const hMaxReference =
    configurazione.hMaxReference || altezzeMinMaxPrimaRender.max

  //////////////////////////////////////
  // PENDENTI

  // Pendenti ordinati per progressivo
  const pendentiOrdinati = configurazione.pendenti.sort(
    (a, b) => +a.progressivo - +b.progressivo
  )

  // Aggiunta oggetti tipoPendente, tipoColorePendente
  // e array tipiColoriPendenti
  const pendentiOrdinatiCompleti = pendentiOrdinati.map((pendente) => {
    const tipoPendente = lookups.tipiPendente.find(
      (tp) => tp.id === pendente.tipoPendenteId
    )

    const tipiColoriPendenti = tipoPendente.tipiColorePendente.map((tcp) => {
      const colore = lookups.tipiColorePendente.find(
        (tcpl) => tcpl.id === tcp.id
      )
      return colore
    })

    const tipoColorePendente = lookups.tipiColorePendente.find(
      (tcp) => tcp.id === pendente.tipoColorePendenteId
    )

    return {
      ...pendente,
      tipoPendente,
      tipoColorePendente,
      tipiColoriPendenti,
    }
  })

  // Shadow Detection
  const pendentiShadowDetected = pendentiOrdinatiCompleti.reduce(
    (acc, pendente) => {
      return shadowDetection(acc, pendente)
    },
    pendentiOrdinatiCompleti
  )

  const atLeastOneShadow = pendentiShadowDetected.some((p) => p.shadow)

  // Collision Detection
  const pendentiCollisionDetected = pendentiShadowDetected.reduce(
    (acc, pendente) => {
      return collisionDetection(acc, pendente)
    },
    pendentiShadowDetected
  )

  const atLeastOneCollision = pendentiCollisionDetected.some((p) => p.collision)

  // Controllo che non siano mischiati pendenti colore oro e argento
  // Nel caso di Gold Moon
  const atLeastOneGmGold = pendentiCollisionDetected.find(
    (p) => p.tipoColorePendenteId === 'GM_G'
  )
  const atLeastOneGmSilver = pendentiCollisionDetected.find(
    (p) => p.tipoColorePendenteId === 'GM_S'
  )
  const mixedGmColors = atLeastOneGmGold && atLeastOneGmSilver

  // Riassunto n° pendenti per tipologia
  const numeroPendentiPerTipologia = pendentiCollisionDetected.reduce(
    (acc, pendente) => {
      const nomeColore = pendente.tipiColoriPendenti.find(
        (tcp) => tcp.id === pendente.tipoColorePendenteId
      ).nome

      const diametro = lookups.tipiPendente.find(
        (tp) => tp.id === pendente.tipoPendenteId
      ).diametro

      const getNomePendente = (tipoPendenteId) => {
        switch (tipoPendenteId) {
          case 'PK14':
          case 'PK20':
            return 'PostKrisi'
          case 'SPL':
            return 'Spot Light'
          case 'SL':
            return 'Sweet Light'
          case 'JO':
            return 'Jackie O'
          case 'GM':
            return 'Gold Moon'
          default:
            return ''
        }
      }

      const tipoPendenteId = lookups.tipiPendente.find(
        (tp) => tp.id === pendente.tipoPendenteId
      ).id
      const nomePendente = getNomePendente(tipoPendenteId)

      const tipoPendente = acc.find(
        (p) =>
          p.tipoPendenteId === pendente.tipoPendenteId &&
          p.tipoColorePendenteId === pendente.tipoColorePendenteId
      )

      if (tipoPendente) {
        return acc.map((tp) => {
          if (
            tp.tipoPendenteId === tipoPendente.tipoPendenteId &&
            tp.tipoColorePendenteId === tipoPendente.tipoColorePendenteId
          ) {
            return {
              ...tipoPendente,
              diametro,
              nomePendente,
              numero: tp.numero + 1,
            }
          }
          return tp
        })
      }

      return [
        ...acc,
        {
          tipoPendenteId: pendente.tipoPendenteId,
          tipoColorePendenteId: pendente.tipoColorePendenteId,
          nomeColore,
          diametro,
          nomePendente,
          numero: 1,
        },
      ]
    },
    []
  )

  let hMaxGm = 0
  let hMinSPL = 35

  ///////////////////////////////////////
  // Ritorno la configurazione completa
  return {
    ...configurazione,
    nome: configurazione.nome,
    base: {
      ...configurazione.base,
      lampada,
      tipoDimmerazione,
      tipoColoreBase,
      numeroPendentiPerTipologia,
    },
    altezzeMinMax: altezzeMinMax(configurazione.pendenti),
    hMinReference,
    hMaxReference,
    hMaxGm,
    hMinSPL,
    atLeastOneCollision,
    atLeastOneShadow,
    mixedGmColors,
    pendenti: pendentiCollisionDetected,
  }
}

export const getConfigurazioneDaSalvare = (globalState) => {
  const configurazione = globalState.configurazione

  const { tipoColoreBaseId, tipoDimmerazioneId, connessionePendentiId } =
    configurazione.base

  const base = {
    tipoColoreBaseId,
    tipoDimmerazioneId,
    connessionePendentiId,
  }

  const pendenti = configurazione.pendenti.map((p) => {
    const { progressivo, z, tipoPendenteId, tipoColorePendenteId } = p

    return {
      progressivo,
      z,
      tipoPendenteId,
      tipoColorePendenteId,
    }
  })

  const configurazioneDaSalvare = {
    tipoBaseId: configurazione.base.id,
    nome: configurazione.nome,
    base,
    pendenti,
    hMax: configurazione.hMax,
    isModificatoDefault: configurazione.isModificatoDefault,
  }

  if (configurazione.isSalvata) {
    configurazioneDaSalvare.configurazioneId = parseInt(configurazione.id, 10)
  }

  return configurazioneDaSalvare
}

export const isUnPendente35cm = (globalState) => {
  const { pendenti } = globalState.configurazione
  return pendenti.some((pendente) => pendente.z === 35)
}
