import gql from "graphql-tag";

const CONFIGURAZIONE_SALVATA = gql`
  query configurazione($configurazioneId: Int!) {
    configurazione(configurazioneId: $configurazioneId) {
      id
      nome
      isModificatoDefault
      base {
        id
        forma
        lunghezza
        larghezza
        tipoLampadaId
        tipoColoreBaseId
        tipoDimmerazioneId
        connessionePendentiId
        tipoDisposizione
        wattaggio
        tipiPendenti {
          id
          nome
          diametro
          tipiColorePendente {
            id
            nome
          }
        }
      }
      pendenti {
        progressivo
        tipoPendenteId
        x
        y
        z
        tipoColorePendenteId
      }
      codice
      hMinStandard
      hMaxStandard
    }
  }
`;

export default CONFIGURAZIONE_SALVATA;
