import React from 'react'

// Components
import Layout from '../../shared/Layout/Layout'
import ListaConfigurazioni from './ListaConfigurazioni/ListaConfigurazioni'

const ConfigurazioniSalvate = () => {
  return (
    <Layout dark isLoggedIn title="Configurazioni salvate">
      <div className="container">
        <div className="configurazioni-salvate">
          <ListaConfigurazioni />
        </div>
      </div>
    </Layout>
  )
}

export default ConfigurazioniSalvate
