import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import bespokenLogo from '../../../images/bespoken.svg'
import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md="9">
            Catellani & Smith Srl - Via Cimitero 1/A, 24020 Villa di Serio, BG<br />
            Cap. Soc. € 500.000 i.v. CF, P.IVA e Reg. Impr. BG IT02656220163,
            RIPRODUZIONE RISERVATA &copy; Catellani & Smith
          </Col>
          <Col md="3" className="logo-bespoken">
            <img src={bespokenLogo} alt="" style={{ width: 70 }} />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
