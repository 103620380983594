const getSvgPendente = ({ tipoPendente: { id: tipo }, verso }, colore, proiezione) => {
  switch (tipo) {
    case 'GM':
    case 'JO':
    case 'SPL':
      switch (proiezione) {
        case 'z':
          return `/svg-pendenti/${tipo}-z-${colore}.svg`
        case 'y':
          return verso
            ? `/svg-pendenti/${tipo}-x-${colore}.svg`
            : `/svg-pendenti/${tipo}-y-${colore}.svg`

        case 'x':
          return verso
            ? `/svg-pendenti/${tipo}-y-${colore}.svg`
            : `/svg-pendenti/${tipo}-x-${colore}.svg`
        default:
          return null
      }
    default:
      switch (proiezione) {
        case 'z':
          return `/svg-pendenti/${tipo}-z-${colore}.svg`
        case 'x':
        case 'y':
          return `/svg-pendenti/${tipo}-x-${colore}.svg`
        default:
          return null
      }
  }
}

export default getSvgPendente
