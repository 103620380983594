import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import throttle from 'redux-throttle'
import rootReducer from './rootReducer'

const defaultWait = 100
const defaultThrottleOption = {
  leading: false,
  trailing: true,
}

const throttleMiddleware = throttle(defaultWait, defaultThrottleOption)

const logger = createLogger({
  collapsed: true,
})

const middlewares = [
  thunkMiddleware,
  throttleMiddleware,
  process.env.NODE_ENV !== 'production' && logger,
].filter(Boolean)

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

export default initialState => {
  const store = createStoreWithMiddleware(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  )
  return store
}
