import React from 'react'
import { connect } from 'react-redux'

// Components
import Layout from '../../shared/Layout/Layout'
import Lampada from '../../shared/Lampada/Lampada'

// Style
import './SceltaLampada.scss'

const SceltaLampada = ({ tipiLampada }) => {
  return (
    <Layout dark isLoggedIn>
      <div className="container">
        <section className="heading dark">
          <div className="config-info">
            <h1 className="title">Chandelier Configurator</h1>
          </div>
        </section>
        <h2 className="tip">
          Choose your chandelier to create a new configuration
        </h2>
      </div>
      <div className="lampade">
        <div className="container-cards">
          {tipiLampada &&
            tipiLampada.map((lampada) => (
              <Lampada key={lampada.nome} lampada={lampada} />
            ))}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  tipiLampada: state.lookups.tipiLampada,
})

export default connect(mapStateToProps)(SceltaLampada)
