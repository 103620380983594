import React from 'react'
import { Link } from 'react-router-dom'

import './Lampada.scss'

const Lampada = ({ lampada }) => {

  return (
    <Link to={`/configurazioni/nuova/${lampada.id}`} className="lampada">
      <img src={`/images/${lampada.id}.jpg`} alt={lampada.nome} className="img-fluid" />
      <h5>{lampada.nome}</h5>
    </Link>
  )
}

export default Lampada
